"use strict";

var _stringUtils = require("./utils/string-utils");
var domain = window.location.protocol + '//' + window.location.host;
var href;
if (domain.indexOf('mobilesearch') > -1) {
  href = '/mobile_search/index_mobile_search.html';
} else if (domain.indexOf('the-subtext') > -1) {
  href = '/the_subtext/index_the_subtext.html';
} else if (domain.indexOf('newsflashreport') > -1) {
  href = '/news_flashreport/index_news_flashreport.html';
} else if (domain.indexOf('search-the-news') > -1) {
  href = '/search_the_news/index_search_the_news.html';
} else if (domain.indexOf('news-feeder') > -1) {
  href = '/news_feeder/index_news_feeder.html';
} else if (domain.indexOf('web-results') > -1) {
  href = '/web_results/index_web_results.html';
} else if (domain.indexOf('mobilequerry') > -1) {
  href = '/mobile_querry/index_mobile_querry.html';
} else if (domain.indexOf('joy-gamez') > -1) {
  href = '/games_wall.html';
} else {
  href = '/index_main.html';
}
if ((0, _stringUtils.isNotEmptyString)(window.location.search)) {
  href = href + window.location.search;
}
self.location.replace(domain + href);